import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from './assets/Logo.svg';
import fb_icon from "./assets/fb_icon.svg";
import instagram_icon from "./assets/instagram_icon.svg";
import x_icon from "./assets/x_icon.svg";
import yt_icon from "./assets/yt_icon.svg";

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [displayedText, setDisplayedText] = useState(''); // Estado para el texto de la animación
  const fullText = 'PRÓXIMAMENTE...';

  useEffect(() => {
    const targetDate = new Date('2024-12-31T23:59:59');
    const timer = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        clearInterval(timer);
      }
    }, 1000);

    let charIndex = 0;
    let isDeleting = false;

    const typingInterval = setInterval(() => {
      setDisplayedText((prev) => {
        if (!isDeleting) {
          // Escritura del texto
          if (charIndex < fullText.length) {
            charIndex++;
            return fullText.slice(0, charIndex);
          } else {
            isDeleting = true;
            return prev;
          }
        } else {
          // Borrado del texto
          if (charIndex > 0) {
            charIndex = 0;
            return '';
          } else {
            isDeleting = false;
            return prev;
          }
        }
      });
    }, 200);

    return () => {
      clearInterval(timer);
      clearInterval(typingInterval);
    };
  }, []);

  return (
    <Container>
      <FlexRow>
        <LineaVertical />
        <CountdownText>{displayedText}</CountdownText>
      </FlexRow>
      <Logo src={logo} alt="Logo" />
      <SocialLinks>
        <SocialLink href="https://x.com/eleditormendoza" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={x_icon} width={30} height={30} alt="x_icon" />
        </SocialLink>
        <SocialLink href="https://www.facebook.com/profile.php?id=61567927192879" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={fb_icon} width={30} height={30} alt="fb_icon" />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/eleditormendoza/" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={instagram_icon} width={30} height={30} alt="instagram_icon" />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/@ElEditorMendoza" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={yt_icon} width={30} height={30} alt="yt_icon" />
        </SocialLink>
      </SocialLinks>
      <LineaButton />
    </Container>
  );
};

export default Countdown;

const blink = keyframes`
  0%, 100% { border-color: transparent; }
  50% { border-color: #fff; }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  color: #fff;
  width: 800px;
  text-align: center;
  gap: 48px;
  @media only screen and (min-width: 0px) and (max-width: 1100px) {
    width: 100%;
    padding: 0px 30px;
    gap: 28px;
  }
`;

const Logo = styled.img`
  width: 100%;
  margin-bottom: 1rem;
  @media only screen and (min-width: 0px) and (max-width: 1100px) {
    margin-bottom: 100%;
  }
`;

const CountdownText = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  min-height:23px;
      margin: auto;
    min-height: 42px;
  border-right: 3px solid #fff;
  animation: ${blink} 1s infinite;
  @media only screen and (min-width: 0px) and (max-width: 430px) {
    font-size: 1.5rem;
    min-height: 31px;
  }
`;

const SocialLinks = styled.div`
  gap: 13px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  margin: 16px;
  flex-direction: column;
`;

const SocialLink = styled.a`
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s;
  &:hover {
    color: #aaa;
  }
`;

const LineaButton = styled.div`
  background-color: #b1043f;
  height: 8px;
  position: absolute;
  bottom: 0px;
  width: 800px;
  @media only screen and (min-width: 0px) and (max-width: 1100px) {
    width: 100%;
    left: 0px;
  }
`;

const LineaVertical = styled.div`
  background-color: #b1043f;
  height: 25px;
  width: 6px;
  margin: auto;
  @media only screen and (min-width: 0px) and (max-width: 430px) {
    height: 20px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
`;
